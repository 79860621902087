import { Atom, Cmp, SHARED_UTILS } from ":mods";
import { SideElementViewProps, SideNavJSXItem } from "../model";
import { For, Show, splitProps } from "solid-js";
// import { actions } from "../../routes/store";
// import { actions } from "../store";

export function SideElement(props: SideElementViewProps) {
  const [local, others] = splitProps(props, ["items", "children", "itemsClass", "base"]);
  return (
    <Atom.Foldable.Accordion
      {...others}
      title={local.children}
      class={`accordion ${others.class ?? ""}`}
      groupClass={`group ${others.groupClass ?? ""}`}
      titleClass={`title ${others.titleClass ?? ""}`}
      activeClass={`${others.activeClass ?? ""}`}
    >
      <Show when={local.items !== undefined && local.items !== null} fallback={"please add some items"}>
        <Show when={Array.isArray(local.items)} fallback={local.items as JSX.Element}>
          <For each={local.items as (Atom.Route.model.AnchorItem | SideNavJSXItem)[]}>
            {(item) => {
              if (typeof item === "function") {
                return (
                  <>
                    {item({
                      actions: {
                        ...Cmp.Alert.actions,
                        ...Cmp.Popup.actions,
                        ...Atom.Route.actions,
                      },
                      base: local.base,
                      classes: { anchor: "anchor" },
                    })}
                  </>
                );
              }
              const to = item.to && SHARED_UTILS.mergeRouteHref({ base: local.base }, item.to);
              // console.log("side element :: ", to);
              return (
                <Atom.Route.Anchor {...item} to={to} class={`anchor ${item.class ?? ""} `} activeClass={`active`} />
              );
            }}
          </For>
        </Show>
      </Show>
    </Atom.Foldable.Accordion>
  );
}
